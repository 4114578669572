<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.formCio.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row
        v-if="forms"
      >
        <v-col
          cols="12"
          sm="12"
          v-for="(item, index) of forms"
          :key="item.ID"
          style="margin: 5px 0;border: 1px solid orange; background-color: #cccccc;"
        >
          <small style="color:orange;">Formulario {{index+1}}:</small>
          <small style="color: red;" @click="handleDeleteItem(item.ID)"> [delete]</small>
          <form-item
            :editedItem="item"
            :locale="locale"
            :languages="languages"
            :onChange="handleChange"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="text-aling: center;"
        >
          <center><v-btn @click="handleAddItem" color="orange"> añadir form</v-btn></center>
        </v-col>
      </v-row>

      <br />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
const defaultItem = { Name: '', Config: []}
import api from '@/services/api'
import utils from '@/services/utils'
import FormItem from './FormItem'
export default {
  name: 'RequestsForm',
  components: {
    FormItem,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    forms: null,
    workspaces: [],
    loading: true,

    editedItem: null,
    languages: ['es', 'en'],
  }),
  methods: {
    getData () {
      api.getAll('dtouch', `v1/system/workspaces/${this.workspaceID}/form-cio-config`)
        .then(response => {
            this.forms = response.map(item => {
              return {
                ID: item.ID,
                Name: item.Name,
                MessageConfirm: item.MessageConfirm ? JSON.parse(item.MessageConfirm) : { es : 'Registro creado correctamente'},
                NotificationMails: item.NotificationMails,
                Config: JSON.parse(item.Config)
              }
            })
          //this.forms = response ? response.Forms : null
        })
    },
    handleExpand () {
      if(!this.forms && this.workspaceID) this.getData()
    },
    handleChange (v) {
      for (let i = 0; i < this.forms.length ; i++) {
        if (this.forms[i].ID === v.ID) this.forms[i] = v
      }
      this.onChangeData(this.forms)
    },
    handleAddItem () {
      const aux = JSON.parse(JSON.stringify(defaultItem))
      aux.ID = utils.getNewID()
      this.forms.push(aux)
      this.onChangeData(this.forms)
    },
    handleDeleteItem (v) {
      this.forms = this.forms.filter(x => x.ID !== v)
      this.onChangeData(this.forms)
    },
  },
}
</script>

