<template>
  <v-autocomplete
    v-if="contentTypes"
    small-chips
    clearable
    dense
    deletable-chips
    multiple
    solo
    :items="contentTypes"
    item-value="id"
    item-text="name"
    hide-details
    prepend-inner-icon="mdi-filter"
    v-model="selectedContentTypes"
    @change="onChange(selectedContentTypes)"
  />
</template>
<script>
import api from '@/services/api'
export default {
  name: 'ContentListFilter',
  props: {
    locale: {
      type: String,
      required: true
    },
    workspaceID: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    contentTypes: null,
    selectedContentTypes: null,
  }),
  watch: {
    workspaceID () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.loading = true
      api.getItem ('dtouch', 'v1/private/workspaces/', this.workspaceID.concat('/content-types'))
        .then((response) => {
          this.contentTypes = response
            .filter(x => x.id !== 1 && x.migrated)
            .map(item => {
              item.name = this.$t(`contentType.${item.name}`, this.locale)
              return item
            })
          this.loading = false
        })
    },
  },
}
</script>
<style scoped>
.v-menu__content {
  margin-left: 10px;
}
.simulation-languages-settings {
  padding-bottom: 20px;
}
.simulation-languages-settings .title {
  text-align: center;
  text-transform: uppercase;
}
.simulation-languages-settings .actions {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}
</style>

