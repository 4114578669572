<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.design.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs12>
        </v-flex>
        <v-flex xs12>
          <color-picker-item
            :value="privateBGColor"
            :label="$t('dtouch.workspace.design.workspaceColor', locale)"
            :onChange="handleChangeWorkspaceColor"
            borderRadius="20px 0 0 0"
          />
          <color-picker-item
            :value="btnBGColor"
            :label="$t('dtouch.workspace.design.btnBGColor', locale)"
            :onChange="handleChangeBtnBGColor"
            borderRadius="0 0 0 0"
          />
          <color-picker-item
            :value="btnTextColor"
            :label="$t('dtouch.workspace.design.btnTextColor', locale)"
            :onChange="handleChangeBtnTextColor"
            borderRadius="0 0 0 0"
          />
          <color-picker-item
            :value="foodDrinkBGColor"
            :label="$t('dtouch.workspace.design.foodDrinkBGColor', locale)"
            :onChange="handleChangeFoodDrinkBGColor"
            borderRadius="0 0 0 0"
          />
          <color-picker-item
            :value="foodDrinkTextColor"
            :label="$t('dtouch.workspace.design.foodDrinkTextColor', locale)"
            :onChange="handleChangeFoodDrinkTextColor"
            borderRadius="0 0 20px 0"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="expandFirstElement"
            :label="$t('dtouch.workspace.design.expandFirstElement', locale)"
            @change="handleChange"
            hide-details
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="expandFirstElementWithoutMainMenu"
            :label="$t('dtouch.workspace.design.expandFirstElementWithoutMainMenu', locale)"
            @change="handleChange"
            hide-details
          />
        </v-flex>
        <v-flex xs12 style="background-color: #CCCCCC80;padding: 0 10px 10px 10px;border-radius:10px;margin-top:10px;">
          <v-switch
            v-model="designByCards"
            :label="$t('dtouch.workspace.design.designByCards', locale)"
            @change="handleChange"
            hide-details
          />
          <img :src="designByCards ? designByCard : designDefault" style="width:100%;height:auto;" />
          <small v-if="designByCards" style="margin: 30px 0 0 0">Forzar Logo (solo móviles):</small>
          <div style="padding: 10px;border: 1px solid #cccccc;">
          <select-image 
            v-if="designByCards"
            :image="designByCardsLogo"
            :onChange="handleChangeLogo"
            :locale="locale"
            :folder="workspaceID"
          />
          </div>
          <v-checkbox
            v-if="designByCards"
            v-model="designByCardsProminent"
            :label="$t('dtouch.workspace.design.designByCardsProminent', locale)"
            @change="handleChange"
            hide-details
          />
          <img :src="designByCardsProminent ? barProminent : barImage" style="width:100%;height:auto;" />
          <v-checkbox
            v-if="designByCards"
            v-model="designByCardsBarNoImage"
            :label="$t('dtouch.workspace.design.designByCardsBarNoImage', locale)"
            @change="handleChange"
            hide-details
          />
            <img :src="designByCardsBarNoImage ? barColor : barImage" style="width:100%;height:auto;" />
        </v-flex>
        <v-flex xs12>
          <v-checkbox
            v-model="cacheFormData"
            :label="$t('dtouch.workspace.design.cacheFormData', locale)"
            @change="handleChange"
            hide-details
          />
        </v-flex>
        <v-flex xs5 style="padding: 20px 10px;">
          <span class="v-label theme--light">Apple icon:</span>
        </v-flex>
        <v-flex xs7 style="padding: 0 10px 20px 0;">
          <select-image 
            :image="appleIcon"
            :onChange="handleChangeAppleIcon"
            :locale="locale"
            :imageWidth="120"
            :imageHeight="120"
            :aspectRatio="1/1"
            :folder="workspaceID"
            style="margin-top: 10px; width:120px;"
          />
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import barImage from '@/assets/barImage.png'
import barColor from '@/assets/barColor.png'
import barProminent from '@/assets/barProminent.png'
import designByCards from '@/assets/designByCards.png'
import designDefault from '@/assets/designDefault.png'
import api from '@/services/api'
import SelectImage from '@/components/selectImage/Index'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'DesignForm',
  components: {
    ColorPickerItem,
    SelectImage
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    privateBGColor: null,
    btnBGColor: null,
    btnTextColor: null,
    foodDrinkBGColor: null,
    foodDrinkTextColor: null,

    expandFirstElement: false,
    expandFirstElementWithoutMainMenu: false,

    designByCards: false,
    designByCardsLogo: null,
    designByCardsProminent: true,
    designByCardsBarNoImage: true,

    appleIcon: null,
    newAppleIcon: null,
    cacheFormData: null,
  }),
  computed: {
    barColor () {
      return barColor
    },
    barImage () {
      return barImage
    },
    barProminent () {
      return barProminent
    },
    designByCard () {
      return designByCards
    },
    designDefault () {
      return designDefault
    },
  },
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/design`)
        .then(response => {
          this.appleIcon = response.AppleIcon
          this.privateBGColor = response.PrivateBGColor
          this.btnBGColor = response.BtnBackgroundColor
          this.btnTextColor = response.BtnTextColor
          this.foodDrinkBGColor = response.FoodDrinkBGColor
          this.foodDrinkTextColor = response.FoodDrinkTextColor
          this.expandFirstElement = response.ExpandFirstElement ? true : false
          this.expandFirstElementWithoutMainMenu = response.ExpandFirstElementWithoutMainMenu ? true : false
          this.designByCards = response.DesignByCards ? true : false
          this.designByCardsLogo = response.DesignByCardsLogo
          this.designByCardsProminent = response.DesignByCardsProminent ? true : false
          this.designByCardsBarNoImage = response.DesignByCardsBarNoImage ? true : false
          this.cacheFormData = response.CacheFormData ? true : false
        })
    },
    handleExpand () {
      if(!this.privateBGColor && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        AppleIcon: this.newAppleIcon,
        PrivateBGColor: this.privateBGColor,
        BtnBGColor: this.btnBGColor,
        BtnTextColor: this.btnTextColor,
        FoodDrinkBGColor: this.foodDrinkBGColor,
        FoodDrinkTextColor: this.foodDrinkTextColor,
        ExpandFirstElement: this.expandFirstElement,
        ExpandFirstElementWithoutMainMenu: this.expandFirstElementWithoutMainMenu,
        DesignByCards: this.designByCards,
        DesignByCardsLogo: this.designByCardsLogo,
        DesignByCardsLogoNewImage: this.designByCardsLogoNewImage,
        DesignByCardsProminent: this.designByCardsProminent,
        DesignByCardsBarNoImage: this.designByCardsBarNoImage,
        CacheFormData: this.cacheFormData
      })
    },
    handleChangeAppleIcon (v) {
      this.appleIcon = v.base64
      this.newAppleIcon = v
      this.handleChange()
    },
    handleChangeWorkspaceColor (v) {
      this.privateBGColor = v
      this.handleChange()
    },
    handleChangeBtnBGColor (v) {
      this.btnBGColor = v
      this.handleChange()
    },
    handleChangeBtnTextColor (v) {
      this.btnTextColor = v
      this.handleChange()
    },
    handleChangeFoodDrinkBGColor (v) {
      this.foodDrinkBGColor = v
      this.handleChange()
    },
    handleChangeFoodDrinkTextColor (v) {
      this.foodDrinkTextColor = v
      this.handleChange()
    },
    handleChangeLogo (v) {
      if (!v) return 

      this.designByCardsLogo = v.base64
      this.designByCardsLogoNewImage = v
      this.handleChange()
    },
  },
}
</script>

